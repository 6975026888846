<template>
  <div class="group cursor-pointer relative inline-block text-center">
    <button
      :style="{ backgroundColor, color }"
      :class="{ 'border border-gray-200 shadow': active }"
      class="hover:opacity-80 rounded px-2 py-1 text-sm mr-2"
    >
      <span>
        {{ abbreviation }}
      </span>
    </button>
    <div
      style="z-index: 9999"
      class="
        opacity-0
        w-18
        bg-black
        text-white text-center text-xs
        rounded-lg
        py-2
        absolute
        z-10
        group-hover:opacity-90
        bottom-full
        -left-1/2
        px-3
        pointer-events-none
      "
    >
      {{ category.name }}
      <svg
        class="absolute text-black h-2 w-full left-0 top-full"
        x="0px"
        y="0px"
        viewBox="0 0 255 255"
        xml:space="preserve"
      >
        <polygon class="fill-current" points="0,0 127.5,127.5 255,0" />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "CategoriesBadge",
  props: ["category", "active"],
  computed: {
    abbreviation() {
      const arr = this.$props.category.name.split(" ");
      const abbrev = arr.map((word) => word[0]);

      return abbrev.join("").toUpperCase();
    },
    backgroundColor() {
      if (this.active) {
        return "#fff";
      } else {
        return this.category.color;
      }
    },
    color() {
      if (this.active) {
        return "black";
      } else {
        return "white";
      }
    },
  },
};
</script>

<style>
</style>