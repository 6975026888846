<template>
  <div class="mt-20 w-full mx-auto text-gray-900">
    <form v-if="mode === 'add'" class="bg-white">
      <h3 class="text-1xl text-gray-900 font-semibold pl-4">Add Category</h3>

      <div class="flex items-center text-sm pl-4">
        <input
          v-model="newCategory.name"
          type="text"
          placeholder="Category"
          class="border rounded-md my-4 pl-4 py-2 px-2 w-1/5"
        />
        <input
          v-model="newCategory.color"
          class="w-10 h-10 my-4 rounded-md m-1"
          type="color"
        />
        <input
          @click.prevent="handleAdd"
          :disabled="!canAdd"
          :class="{ 'cursor-not-allowed': !canAdd }"
          type="submit"
          value="Submit"
          class="
            w-20
            bg-gray-600
            rounded-md
            m-1
            hover:bg-gray-500
            text-white text-sm
            font-semibold
            p-2
            py-2
            px-2
            my-4
          "
        />
      </div>
    </form>

    <form v-if="mode === 'edit'" class="bg-white">
      <h3 class="text-1xl text-gray-900 font-semibold pl-4">
        EDIT Category - {{ editingCategory.name }}
      </h3>

      <div class="flex items-center text-sm pl-4">
        <input
          v-model="editingCategory.name"
          type="text"
          placeholder="Category"
          class="border rounded-md my-4 pl-4 py-2 px-2 w-1/5"
        />
        <input
          v-model="editingCategory.color"
          class="w-10 h-10 my-4 rounded-md m-1"
          type="color"
        />
        <button
          @click.prevent="
            (editingCategory = { name: '', color: '' }), (mode = 'add')
          "
          class="
            w-20
            bg-gray-100
            rounded-md
            m-1
            hover:bg-gray-200
            text-sm
            font-semibold
            p-2
            py-2
            px-2
            my-4
          "
        >
          Cancel
        </button>
        <input
          type="submit"
          value="Submit"
          class="
            w-20
            bg-gray-600
            rounded-md
            m-1
            hover:bg-gray-500
            text-white text-sm
            font-semibold
            p-2
            py-2
            px-2
            my-4
          "
        />
      </div>
    </form>

    <div class="w-full px-4">
      <div class="bg-white shadow-md rounded my-6">
        <table class="text-left w-full border-collapse">
          <!--Border collapse doesn't work on this site yet but it's available in newer tailwind versions -->
          <thead>
            <tr>
              <th
                class="
                  py-4
                  px-6
                  bg-gray-100
                  font-bold
                  uppercase
                  text-sm text-grey-dark
                  border-b border-grey-light
                "
              >
                Badge
              </th>
              <th
                class="
                  py-4
                  px-6
                  bg-gray-100
                  font-bold
                  uppercase
                  text-sm text-grey-dark
                  border-b border-grey-light
                "
              >
                Categories
              </th>
              <th
                class="
                  py-4
                  px-6
                  bg-gray-100
                  font-bold
                  uppercase
                  text-sm text-grey-dark
                  border-b border-grey-light
                "
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="category in categories"
              :key="category._id"
              class="hover:bg-gray-50"
            >
              <td class="py-0 px-6 border-b border-grey-light">
                <CategoriesBadge :category="category"></CategoriesBadge>
              </td>
              <td class="py-0 px-6 border-b border-grey-light">
                {{ category.name }}
              </td>
              <td class="py-0 px-6 border-b border-grey-light">
                <button
                  @click="
                    (editingCategory = JSON.parse(JSON.stringify(category))),
                      (mode = 'edit'),
                      window.scroll(0, 0)
                  "
                >
                  <span
                    href="#"
                    class="
                      text-grey-lighter
                      font-bold
                      py-0
                      px-3
                      rounded
                      text-xs
                      bg-green
                      hover:bg-green-dark
                    "
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      class="mr-2"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#000000"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path
                        d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"
                      ></path>
                      <polygon
                        points="18 2 22 6 12 16 8 16 8 12 18 2"
                      ></polygon>
                    </svg>
                  </span>
                </button>
                <button
                  @click="
                    (categoryToDelete = category), (deleteConfirmVisible = true)
                  "
                >
                  <span
                    href="#"
                    class="
                      text-grey-lighter
                      font-bold
                      py-0
                      px-3
                      rounded
                      text-xs
                      bg-blue
                      hover:bg-blue-dark
                    "
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#000000"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <polyline points="3 6 5 6 21 6"></polyline>
                      <path
                        d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                      ></path>
                      <line x1="10" y1="11" x2="10" y2="17"></line>
                      <line x1="14" y1="11" x2="14" y2="17"></line>
                    </svg>
                  </span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <!-- DELETE CONFIRM MODAL -->
  <div
    v-if="deleteConfirmVisible"
    @click="deleteConfirmVisible = false"
    class="confirm-overlay"
  ></div>
  <div
    v-if="deleteConfirmVisible && categoryToDelete"
    class="delete-confirm text-xl shadow rounded p-10"
  >
    <div>
      Delete Category {{ categoryToDelete.name }}?
      <br />
      <span class="inline-block my-4 text-red-700"
        >This action cannot be undone.</span
      >
    </div>
    <div class="flex justify-end mt-6">
      <button
        @click="(deleteConfirmVisible = false), (categoryToDelete = null)"
        class="py-2 px-4 bg-green-600 text-white rounded mr-2"
      >
        No
      </button>
      <button
        @click="(deleteConfirmVisible = false), handleDelete(categoryToDelete)"
        class="py-2 px-4 bg-red-600 text-white rounded"
      >
        Yes
      </button>
    </div>
  </div>
  <!-- / DELETE CONFIRM MODAL -->
</template>


<script>
import CategoriesBadge from "@/components/base/CategoriesBadge";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Categories",
  data() {
    return {
      // either add/edit
      deleteConfirmVisible: false,
      categoryToDelete: null,
      mode: "add",
      newCategory: {
        name: "",
        color: "#cf325e",
      },
      editingCategory: {
        name: "",
        color: "#cf325e",
      },
      window: window,
    };
  },
  methods: {
    ...mapActions(["getCategories", "addCategory", "deleteCategory"]),
    handleDelete() {
      if (this.categoryToDelete === null) {
        this.deleteConfirmVisible = false;
        return null;
      }
      this.deleteCategory(this.categoryToDelete)
        .then(() => (this.categoryToDelete = null))
        .catch(() => (this.categoryToDelete = null));
    },
    handleAdd() {
      this.addCategory(this.newCategory);
      this.newCategory = {
        name: "",
        color: "#cf325e",
      };
    },
  },
  computed: {
    ...mapGetters(["categories"]),
    canAdd() {
      return this.newCategory.name && this.newCategory.color;
    },
  },
  components: {
    CategoriesBadge,
  },
  mounted() {
    this.getCategories();
  },
};
</script>

<style scoped>
.delete-confirm {
  position: fixed;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
}

.confirm-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9998;
}
</style>